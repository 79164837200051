import {onFind} from "@elements/init-modules-in-scope";
import BlazeSlider from 'blaze-slider';
import {findIn, addClass} from '@elements/dom-utils';

const isMobile = matchMedia('(max-width: 767px)').matches || false;
const isTablet = matchMedia('(max-width: 1400px)').matches || false;

export function init() {
    onFind('.js-blaze-slider.js-blaze-slider--gallery', function (baseElement) {
        const teaserSlider = new BlazeSlider(baseElement, {
            all: {
                loop: true,
                slidesToScroll: 1,
                slideGap: '10px',
                slidesToShow: 1,
                draggable: true,
            }
        });
    });
    onFind('.js-blaze-slider.js-blaze-slider--pdp', function (baseElement) {
        const teaserSlider = new BlazeSlider(baseElement, {
            all: {
                loop: true,
                slidesToScroll: 1,
                slideGap: '60px',
                slidesToShow: 1,
                draggable: true,
            }
        });
    });
    onFind('.js-blaze-slider.js-blaze-slider--pdp-accessories', function (baseElement) {
        if (isMobile ) {
            const teaserSlider = new BlazeSlider(baseElement, {
                all: {
                    loop: true,
                    slidesToScroll: 1,
                    slideGap: '1px',
                    slidesToShow: 1.1,
                    draggable: true,
                }
            });

            checkSliderItems(baseElement, teaserSlider.totalSlides, teaserSlider.config.slidesToShow);

        }else {
            const teaserSlider = new BlazeSlider(baseElement, {
                all: {
                    loop: true,
                    slidesToScroll: 1,
                    slideGap: '1px',
                    slidesToShow: 2,
                    draggable: true,
                }
            });

            checkSliderItems(baseElement, teaserSlider.totalSlides, teaserSlider.config.slidesToShow);
        }
    });
    onFind('.js-blaze-slider.js-blaze-slider--teaser', function (baseElement) {
        let mobileGrid = baseElement.getAttribute('data-grid');

        if (isMobile ) {
            if (!mobileGrid){
                const teaserSlider = new BlazeSlider(baseElement, {
                    all: {
                        loop: true,
                        slidesToScroll: 1,
                        slideGap: '1px',
                        slidesToShow: 1.1,
                        draggable: true,
                        transitionDuration: 500,
                        enablePagination: true,
                    }
                });
            }else {
                addClass("static", baseElement)
            }

        } else if (!isMobile && isTablet) {
            const teaserSlider = new BlazeSlider(baseElement, {
                all: {
                    loop: true,
                    slidesToScroll: 3,
                    slideGap: '1px',
                    slidesToShow: 3,
                    transitionDuration: 800,
                }
            });

            checkSliderItems(baseElement, teaserSlider.totalSlides, teaserSlider.config.slidesToShow);
        } else {
            const teaserSlider = new BlazeSlider(baseElement, {
                all: {
                    loop: true,
                    slidesToScroll: 4,
                    slideGap: '7px',
                    slidesToShow: 4,
                    transitionDuration: 800,
                }
            });

            checkSliderItems(baseElement, teaserSlider.totalSlides, teaserSlider.config.slidesToShow);
        }
    });
    onFind('.js-blaze-slider.js-blaze-slider--highlight', function (baseElement) {
        //check if js-blaze-slider has attribute "data-random-slides"
        let randomSlides = baseElement.getAttribute('data-random-slides');
        if(randomSlides) {
            let sliderTrack = baseElement.querySelector('.blaze-track');
            let items = sliderTrack.querySelectorAll('.js-highlight-teaser');
            let itemsArr = Array.prototype.slice.call(items);
            itemsArr.sort(function() {
                return Math.random() - 0.5;
            });
            for (let i = 0; i < itemsArr.length; i++) {
                sliderTrack.appendChild(itemsArr[i]);
            }
        }

        if (isMobile ) {
            const teaserSlider = new BlazeSlider(baseElement, {
                all: {
                    loop: true,
                    slidesToScroll: 1,
                    slideGap: '1px',
                    slidesToShow: 1,
                    draggable: true,
                    transitionDuration: 500,
                    enablePagination: true,
                }
            });
        } else {
            const teaserSlider = new BlazeSlider(baseElement, {
                all: {
                    loop: true,
                    slidesToScroll: 1,
                    slideGap: '10px',
                    slidesToShow: 1,
                    transitionDuration: 800,
                }
            });

            checkSliderItems(baseElement, teaserSlider.totalSlides, teaserSlider.config.slidesToShow);
        }
    });
    onFind('.js-blaze-slider.js-blaze-slider--benefits', function (baseElement) {
        if (isMobile ) {
            const benefitsSlider = new BlazeSlider(baseElement, {
                all: {
                    loop: true,
                    slidesToScroll: 1,
                    slideGap: '1px',
                    slidesToShow: 1,
                    draggable: true,
                    transitionDuration: 500,
                    enablePagination: true,
                }
            });
        } else {
            const benefitsSlider = new BlazeSlider(baseElement, {
                all: {
                    loop: true,
                    slidesToScroll: 1,
                    slideGap: '10px',
                    slidesToShow: 3,
                    draggable: true,
                }
            });

            checkSliderItems(baseElement, benefitsSlider.totalSlides, benefitsSlider.config.slidesToShow);
        }
    });
}

function checkSliderItems(element, totalSlides, slidesToShow) {
    let arrows = findIn('.js-blaze__arrows', element)

    if (arrows && totalSlides <= slidesToShow) {
        arrows.setAttribute('hidden', 'hidden');
    }
}