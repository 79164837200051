import {onFind} from "@elements/init-modules-in-scope";
import {removeClass,addClass,hasClass,on,closest,findIn, findAllIn } from '@elements/dom-utils';
import * as formValidation from "@elements/form-validation";
import {showNotification} from "@elements/alert-notification";
import formDataEntries from 'form-data-entries';
import Modal from 'bootstrap/js/dist/modal';



const selectors = {
    base: '.js-add-cart',
    input: '.js-add-cart__input',
    btn: '.js-add-cart__btn',
    loading: '.js-add-cart__loading',
    result: '.js-add-cart__result',
    form: '.js-add-cart__form',
    error: '.js-add-cart__error'
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        let btns = findAllIn(selectors.btn, baseElement);
        let input = findIn(selectors.input, baseElement);
        let loading = findIn(selectors.loading, baseElement);
        let form = findIn(selectors.form, baseElement);
        let errorMessage = findIn(selectors.error, baseElement);
        let url;

        btns.forEach((btn) => {
            on("click", function (event) {
                event.preventDefault();

                url = form.getAttribute("data-action");
                loading.removeAttribute('hidden');

                let data = new URLSearchParams(formDataEntries(form));

                if (btn.getAttribute("data-new-cart") === "true"){
                    url = btn.getAttribute("data-formaction")
                    input.setAttribute("required", true)

                    if (input.value === ""){
                        addClass("is-invalid", input);
                        errorMessage.removeAttribute('hidden');
                        loading.setAttribute('hidden', true);

                    }else{
                        removeClass("is-invalid", input)
                        errorMessage.setAttribute('hidden', true);
                        submitForm(data, url, loading, baseElement)
                    }
                }else{
                    removeClass("is-invalid", input)
                    data.append("add-cart", btn.getAttribute("data-name"))
                    data.delete(input.name)

                    submitForm(data, url, loading, baseElement)
                }
            }, btn)
        })
    });
}

function submitForm(data, url, loading, baseElement){
    if (pendingRequest) {
        pendingRequest.abort();
        pendingRequest = null;
    }

    let pendingRequest = fetch( url, {
        method: "POST",
        body: data,
    });

    showNotification({closable: true});
    showNotification(pendingRequest);

    pendingRequest.then((res) => {
        return res.clone().json()
    }).then((res) => {
        if (res.success) {
            let myModal = Modal.getInstance(closest("#dynamicModal", baseElement))
            myModal.hide()
        }

        loading.setAttribute('hidden', true);
    }).catch((error) => {
        if (error.name !== 'AbortError') {
            console.error(error);

            loading.setAttribute('hidden', true);
        }
    });
}
